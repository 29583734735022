<template>
    <div class="tax-commit">
       <p class="title">《公示登记承诺书》</p>
       <p class="des first">根据相关法律规定，本人在平台内提供的推广服务为个人零星小额交易活动，本人作为推广服务提供者不需要进行市场主体登记。本人将保证依法履行纳税义务，合法合规经营。<span>如费用支付方因财税处理需要，根据国家税务总局公告2018年第28号第九条规定，本人授权平台将文件规定相关信息处理后给予平台或商家使用。</span></p>
       <p class="des second">如本人从事的上述推广服务发生变化，本人将按照相关法律规定及时办理市场主体工商登记，并履行其他法定义务。</p>
       <p class="des second">承诺人：</p>
       <div class="fixed">
        <div class="tax-b flex align-center flex-center">
            <img
            v-show="!isAgree"
            src="@/assets/img/loginRegister/no-select.png"
            @click="handleAgree"
          />
          <img
            v-show="isAgree"
            src="@/assets/img/loginRegister/select.png"
            @click="handleAgree"
          />
          <p @click="handleAgree">我已经阅读并了解<span @click.stop="hrefFun">《公示登记承诺书》</span></p>
        </div>
        <div :class="['b-last flex align-center', {'b-red': isAgree}]" @click="sureFun">
            <p>确认签署</p>
        </div>
       </div>

    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>