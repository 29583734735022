import {
    confirmTax
} from '@/utils/user.js';
export default {
    name: "TaxCommit",
    data() {
        return {
            isAgree: false
        }
    },
    methods: {
        hrefFun() {
            window.location.href = "/agreement/notice.html"
        },
        handleAgree() {
            this.isAgree = this.isAgree ? false : true
        },
        sureFun() {
            if (this.isAgree) {
                let data = {
                    memberId: this.$cookies.get('memberId')
                };
                confirmTax(data).then(res => {
                    if (res.code == "0") {
                        this.$toast.success('确认签署成功')
                        setTimeout(() => {
                            this.$router.go(-1)      
                        }, 400) 
                    }
                })
            }
        }
    }
}